// ******************************************************************
// HEADERS
$elevator_width: 30px;

// ******************************************************************
// HEADERS
.monitoring__barIcon {
	font-size: 24px;
	margin-left: 10px;
	margin-right: 10px;
}

// ******************************************************************
// CARDS
.monitoring__card__container {
	height: 100%;
	position: relative;
	overflow-y: auto;
}
.monitoring__card__dark {
	background-color: $item__monitoring__card__dark;
	border-radius: 6px;
	gap: 16px;
}
.monitoring__card__light {
	background-color: $item__monitoring__card__light;
	border-radius: 6px;
	gap: 16px;
}
.monitoring__card__blue {
	background-color: $item__monitoring__card__blue;
	border-radius: 6px;
	gap: 16px;
}
.monitoring__card__header {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	padding: 10px;
	margin-bottom: -10px;
}
.monitoring__card__dotStyle__dark__active {
	border-top: dotted 1px $item__monitoring__floorDots__dark;
	margin-top: -13px;
}
.monitoring__card__dotStyle__dark__inactive {
	border-top: dotted 1px rgba($item__monitoring__floorDots__dark, 0.4);
	margin-top: -13px;
}
.monitoring__card__dotStyle__light__active {
	border-top: dotted 1px $item__monitoring__floorDots__light;
	margin-top: -13px;
}
.monitoring__card__dotStyle__light__inactive {
	border-top: dotted 1px rgba(189, 189, 189, 0.4);
	margin-top: -13px;
}
.monitoring__card__dotStyle__blue__active {
	border-top: dotted 1px $item__monitoring__floorDots__blue;
	margin-top: -13px;
}
.monitoring__card__dotStyle__blue__inactive {
	border-top: dotted 1px rgba($item__monitoring__floorDots__blue, 0.4);
	margin-top: -13px;
}
.monitoring__card__liftContainer__dark {
	background-color: $item__monitoring__floor__dark;
	width: $elevator_width;
	text-align: center;
	height: 25px;
}
.monitoring__card__liftContainer__light {
	background-color: $item__monitoring__floor__light;
	width: $elevator_width;
	text-align: center;
	height: 25px;
}
.monitoring__card__liftContainer__blue {
	background-color: $item__monitoring__floor__blue;
	width: $elevator_width;
	text-align: center;
	height: 25px;
}
.monitoring__card__elevatorContainer__dark {
	width: $elevator_width;
	text-align: center;
	height: 25px;
	color: gray;
	font-size: 11px;
	margin-top: 5px;
}
.monitoring__card__elevatorContainer__light {
	width: $elevator_width;
	text-align: center;
	height: 25px;
	color: gray;
	font-size: 11px;
	margin-top: 5px;
}
.monitoring__card__elevatorContainer__blue {
	width: $elevator_width;
	text-align: center;
	height: 25px;
	color: $item__monitoring__floorDots__blue;
	font-size: 12px;
	margin-top: 5px;
}
.monitoring__card__liftContainerBorderTop {
	border-radius: 8px 8px 0px 0px;
}
.monitoring__card__liftContainerBorderBottom {
	border-radius: 0px 0px 8px 8px;
}
.monitoring__card__floor__inactive__dark {
	color: #414141;
}
.monitoring__card__floor__door__dark {
	color: rgb(214, 214, 214);
}
.monitoring__card__floor__door__light {
	color: black;
}
.monitoring__card__floor__door__dark:hover,
.monitoring__card__floor__door__light:hover,
.monitoring__card__floor__door__blue:hover {
	color: $item__warning;
}
.monitoring__card__floor__inactive__light {
	color: rgb(226, 226, 226);
}
.monitoring__card__floor__inactive__blue {
	color: #284b70;
}
.monitoring__card__floor__active__yellow__dark,
.monitoring__card__floor__active__yellow__blue,
.monitoring__card__floor__active__yellow__light {
	font-weight: bolder;
	color: $item__warning;
}
.monitoring__card__floor__active__green__dark,
.monitoring__card__floor__active__green__blue,
.monitoring__card__floor__active__green__light {
	font-weight: bolder;
	color: $item__success;
}
.monitoring__card__floor__active__red__dark,
.monitoring__card__floor__active__red__blue,
.monitoring__card__floor__active__red__light {
	font-weight: bolder;
	color: $item__error;
}
.monitoring__card__floor {
	text-align: center;
	height: 20px;
	width: 20px;
	border-radius: 100px;
	padding-top: 3px;
	font-size: 10px;
}
.monitoring__headerContainer__dark {
	border-bottom: solid 1px $item__background__dark;
	margin-top: -20px;
	padding-bottom: 10px;
}
.monitoring__headerContainer__light {
	border-bottom: solid 1px $item__info;
	margin-top: -20px;
	padding-bottom: 10px;
}
.monitoring__headerContainer__blue {
	border-bottom: solid 1px $item__monitoring__card__blue;
	margin-top: -20px;
	padding-bottom: 10px;
}
.ant-tabs-nav-list {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.ant-tabs-tab {
	width: 100px;
	justify-content: center;
}
.monitoringView__detailsBox {
	width: 100%;
}
.montoringView__controlPanel {
	border-radius: 10px;
	padding: 20px;
	padding-bottom: 25px;
}
.montoringView__controlPanel__light {
	background-color: #f0f2f5;
	border-radius: 10px;
	padding: 10px 10px 0px 10px;
	height: 110px;
}
.montoringView__controlPanel__dark {
	background-color: #141414;
	border-radius: 10px;
	padding: 10px 10px 0px 10px;
	height: 110px;
}
.montoringView__controlPanel__blue {
	background-color: #0c1a28;
	border-radius: 10px;
	padding: 10px 10px 0px 10px;
	height: 110px;
}
.monitoringView__controlPanelNumber {
	width: 50px;
	height: 50px;
	margin: 5px;
	border-radius: 100px;
	text-align: center;
	padding-top: 13px;
	font-size: 14px;
}
.monitoringView__controlPanelNumber__dark {
	border: solid 1px #404040;
	color: white;
}
.monitoringView__controlPanelNumber__light {
	border: solid 1px #f0f2f5;
	color: $item__monitoring__floorDots__dark;
}
.monitoringView__controlPanelNumber__blue {
	border: solid 1px #0d1a28;
	color: $item__monitoring__floorDots__dark;
}
.monitoringView__controlPanelNumber__active__dark {
	border: solid 1px $item__info !important;
	color: white !important;
	font-weight: bolder;
	background-color: rgb(41, 104, 192, 0.5) !important;
}
.monitoringView__controlPanelNumber__active__light {
	border: solid 1px $item__info !important;
	color: white !important;
	font-weight: bolder;
	background-color: rgb(41, 104, 192, 0.5) !important;
}
.monitoringView__controlPanelNumber__active__blue {
	border: solid 1px $item__info !important;
	color: white !important;
	font-weight: bolder;
	background-color: rgb(41, 104, 192, 0.5) !important;
}

.monitoringView__controlPanelNumber__light {
	background-color: #f0f2f5;
}
.monitoringView__controlPanelNumber__dark {
	background-color: #141414;
}
.monitoringView__controlPanelNumber__blue {
	background-color: #0c1a28;
}
.monitoringView__controlPanelDetails {
	margin-right: 15px;
	border-radius: 8px;
	padding: 0px 5px 0px 5px;
	display: flex;
}
.monitoringView__controlPanelDetails__dark {
	border: solid 2px #141414;
}
.monitoringView__controlPanelDetails__light {
	border: solid 2px #f0f2f5;
}
.monitoringView__controlPanelDetails__blue {
	border: solid 2px #0c1a28;
}
.monitoringView__controlPanelActionIcon {
	border-radius: 8px;
	height: 35px;
	width: 35px;
	border: solid 1px blue;
}
.monitoringView__controlPanelActionIcon__escalator {
	margin-top: -2px;
	margin-left: -1px;
}
.monitoringView__controlPanelActionIcon__dark {
	background-color: #141414;
}
.monitoringView__controlPanelActionIcon__light {
	background-color: #f0f2f5;
}
.monitoringView__controlPanelActionIcon__blue {
	background-color: #0c1a28;
}
.monitoringView__controlPanelActionFloor {
	width: 50px;
	text-align: center;
	font-size: 18px;
	padding-top: 5px;
}
.monitoringView__controlPanelElevator {
	min-height: 400px;
	height: auto;
}
.monitoringView__controlPanelPanel {
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
	flex-direction: row-reverse;
}
.monitoringView__controlPanelPanelButton {
	width: 50px;
	height: 50px;
}
.monitoringView__dangerElevator {
	border-left: solid 2px transparent;
	border-right: solid 2px transparent;
	animation: 3s infinite glowDanger;
}
.monitoringView__dangerElevator__top {
	border-top: solid 2px transparent;
	animation: 3s infinite glowDanger;
}
.monitoringView__dangerElevator__bottom {
	border-bottom: solid 2px transparent;
	animation: 3s infinite glowDanger;
}
.monitoring__card__dotStyle__downHallCalls__dark {
	border-top: dotted 2px transparent;
	animation: 3s infinite glowDanger;
	margin-top: -13px;
}
.monitoring__card__dotStyle__hallCalls__yellow {
	border-top: dotted 2px transparent;
	animation: 2s infinite glowWarning;
	margin-top: -13px;
}
.monitoring__card__dotStyle__hallCalls__green {
	border-top: dotted 2px transparent;
	animation: 2s infinite glowSuccess;
	margin-top: -13px;
}
.monitoring__card__dotStyle__hallCalls__red {
	border-top: dotted 2px transparent;
	animation: 2s infinite glowDanger;
	margin-top: -13px;
}
@keyframes glowDanger {
	50% {
		border-color: $item__error;
	}
}
@-webkit-keyframes glowDanger {
	50% {
		border-color: $item__error;
	}
}
@keyframes glowSuccess {
	50% {
		border-color: $item__success;
	}
}
@-webkit-keyframes glowSuccess {
	50% {
		border-color: $item__success;
	}
}
@keyframes glowWarning {
	50% {
		border-color: $item__warning;
	}
}
@-webkit-keyframes glowWarning {
	50% {
		border-color: $item__warning;
	}
}
.monitoringView__headerTagContainer__light {
	width: 45px;
	border: solid 1px #d9d9d9;
}
.monitoringView__headerTagContainer__dark {
	width: 45px;
	border: solid 1px #434343;
}
.monitoringView__headerTagContainer__blue {
	width: 45px;
	border: solid 1px #434343;
}
.monitoringView__topViewContainer__light {
	background-color: #e2e3e4;
	border-radius: 10px;
}
.monitoringView__topViewContainer__dark {
	background-color: #070707;
	border-radius: 10px;
}
.monitoringView__topViewContainer__blue {
	background-color: #11273f;
	border-radius: 10px;
}
.monitoringView__topViewContainer {
	height: 50px;
	width: 45px;
	margin: 5px;
	margin-top: 1px;
	text-align: center;
}
.monitoringView__topViewNameContainer {
	font-size: 9px;
	font-weight: bolder;
	text-align: center;
}
.monitoringView__headerCarCall {
	border-radius: 100px;
	height: 28px;
	width: 28px;
	font-size: 13px;
	font-weight: bolder;
	text-align: center;
	margin-top: -3px;
	margin-left: 10px;
	padding-top: 4px;
}
.monitoringView__headerCarCall__light {
	background-color: rgba(175, 199, 235, 1);
	border: solid 1px #6ba0f4;
	color: gray;
}
.monitoringView__headerCarCall__dark {
	background-color: rgba(255, 255, 255, 0.5);
	border: solid 1px gray;
}
.monitoringView__headerCarCall__blue {
	background-color: rgba(255, 255, 255, 0.5);
	border: solid 1px gray;
}
.monitoringView__headerHallCall__light {
	background-color: rgb(254, 208, 61, 0.5);
	border: solid 1px $item__warning;
	color: gray;
}
.monitoringView__headerHallCall__dark {
	background-color: rgb(254, 208, 61, 0.5);
	border: solid 1px $item__warning;
}
.monitoringView__headerHallCall__blue {
	background-color: rgb(254, 208, 61, 0.5);
	border: solid 1px $item__warning;
}
.monitoring__notification__dark {
	background-color: $item__monitoring__card__dark;
	color: white;
}
.monitoring__cardHoverWarning:hover {
	-webkit-box-shadow: 0px 0px 5px 1px rgba(243, 149, 48, 1);
	-moz-box-shadow: 0px 0px 5px 1px rgba(243, 149, 48, 1);
	box-shadow: 0px 0px 5px 1px rgba(243, 149, 48, 1);
	.monitoring__cardButtonHover {
		color: $item__warning !important;
	}
	.monitoring__cardIconHover {
		color: $item__warning !important;
	}
}
.monitoring__cardButtonHover {
	color: $item__info !important;
}
.monitoring__cardIconHover {
	color: $item__info !important;
}
.monitoring__cardBorderSelected {
	-webkit-box-shadow: 0px 0px 5px 1px rgba(243, 149, 48, 1);
	-moz-box-shadow: 0px 0px 5px 1px rgba(243, 149, 48, 1);
	box-shadow: 0px 0px 5px 1px rgba(243, 149, 48, 1);
}
.monitoring__100pxBox {
	min-width: 100px;
}
.monitoring__100pxBox2 {
	width: 100px;
}
.monitoring__200pxBox {
	width: 200px;
}
.montoring__150pxBox {
	width: 150px;
}
.monitoring__modalSettings__button__1 {
	width: 40px !important;
	height: 40px !important;
	margin: 5px !important;
	text-align: center;
}
.monitoring__modalSettings__button__2 {
	width: 100px !important;
	height: 50px !important;
	margin: 5px !important;
}
.monitoring__modalSettings__icon {
	margin-right: 4px;
	margin-left: 4px;
	color: gray;
}
.montoring__modalSettingsHeader__liftName {
	font-weight: bolder;
	font-size: 15px;
}
.montoring__modalSettingsHeader__carCallsContainer {
	margin-top: -7px;
}
.montoring__modalSettingsHeader__currentFloorContainer {
	font-size: 25px;
	margin-top: 8px;
}
.montoring__modalSettingsHeader__icon {
	font-size: 28px;
	margin-top: 13px;
	margin-right: 25px;
	margin-left: 25px;
	color: gray;
}
.monitoring__selectFirst__card {
	height: 250px;
	border-end-end-radius: 5px;
	border-radius: 5px;
}
.monitoring__selectFirst__listContainer {
	height: 170px;
	overflow-y: auto;
	padding-left: 20px;
}
.monitoring__selectFirst__cardRadius {
	border-radius: 5px;
}
.monitoring__terminal__jsonText {
	margin-top: -12px;
}
.monitoring__elevatorFullView__container {
	padding: 10px 20px 10px 5px;
	margin-top: -20px;
}
.monitoring__elevatorFullView__dotsContainer {
	position: relative;
	width: 100%;
}
.monitoring__elevatorFullView__dotsStyleContainer {
	position: absolute;
	z-index: 1;
	width: 100%;
}
.monitoring__elevatorFullView__badge {
	font-size: 8px;
}
.monitoring__emptyContainer {
	height: 40px;
}
.monitoring__liftDotElevator {
	width: 35px;
	height: 30px;
	text-align: center;
}
.monitoring__faultTag {
	margin-top: 3px;
	margin-left: 3px;
	font-size: 9px;
	width: 43px;
}
.monitoring__faultTagElevator {
	margin-top: 5px !important;
	margin-left: -5px !important;
	font-size: 8px !important;
	width: 43px;
}
.monitoring__serviceTagElevator {
	margin-top: 5px !important;
	margin-left: -5px !important;
	width: 43px;
}
.monitoring__faultTagTopView {
	margin-top: 5px !important;
	margin-left: 3px !important;
	font-size: 8px !important;
	width: 43px;
}
.monitoring__serviceTagTopView {
	margin-top: 5px !important;
	margin-left: 3px !important;
	width: 43px;
}
.monitoring__fullNameElevatorContainer {
	font-size: 9px;
	font-weight: bolder;
}
.monitoring__liftTopViewIconContainer {
	height: 50px;
	width: 50px;
	display: flex;
	flex-direction: column;
	margin-left: 5px;
	margin-right: 5px;
}
.monitoring__liftStatusViewIconContainer {
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-left: 6px;
	margin-right: 6px;
	margin-top: -10px;
}
.monitoring__liftTopViewIcon {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.monitoring__topViewElevatorName {
	font-size: 9px;
	font-weight: bolder;
	text-align: center;
	margin-top: 5px;
}
.monitoring__statusViewContainer {
	padding: 10px;
}
.monitoring__terminal__container25 {
	width: 25px;
	text-align: center;
}
.monitoring__terminal__floorsContainer {
	width: 75px;
	margin-left: 10px;
}
.monitoring__terminal__dotsContainer {
	width: 100px;
	position: relative;
	margin-left: 10px;
}
.monitoring__sockeConnectionContainer {
	width: 400px;
	margin-left: 15px;
	margin-top: 10px;
}
.monitoring__iconStatus {
	margin-right: 4px;
	margin-left: 4px;
}
.monitoring__advanceSettings__button {
	width: 250px;
	margin: 5px;
}
.monitoring__commandButton__title {
	color: rgb(196, 196, 196);
}
.monitoring__commandButton__title:hover {
	color: white;
}
