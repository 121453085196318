@import "./variables.scss";

// ******************************************************************
// MIXINGS

// ******************************************************************
// GENERAL STYLES
.component__table__noDataBox {
	height: 300px;
}

.component__table__noDataIcon {
	font-size: 25px;
}
