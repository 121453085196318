* {
	margin: 0;
	padding: 0;
}
html,
body {
	width: 100%;
	height: 100%;
	background-color: black;
}
#app,
#root {
	height: 100%;
	width: 100%;
	position: relative;
}
