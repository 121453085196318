@import "./variables.scss";

// ******************************************************************
// MIXINGS
// ******************************************************************
// GENERAL STYLES

.drawer__title {
	font-weight: bolder;
	width: 200px;
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.drawer__titleMobile {
	font-weight: bolder;
	width: 150px;
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.drawer__userNameDrawer {
	color: $item__info;
	font-weight: bolder;
}
.drawer__logoutButton {
	width: 130px;
	background-color: $item__warning !important;
	color: white !important;
	margin-top: 25px;
	border-style: none;
}
.drawer__signOutButtonContainer {
	display: flex;
	justify-content: center;
}
.drawer__container {
	padding-top: 5px;
	padding-bottom: -10px;
	cursor: pointer;
}
.drawer__textContainer {
	position: relative;
	display: block;
	width: fit-content;
	word-wrap: break-word;
}
.drawer__text {
	font-size: 12px;
}
.drawer__box__dark {
	background-color: $item__background__dark;
	padding: 15px;
	box-shadow: 0px 0px 5px 1px rgba(29, 29, 29, 0.75);
	-webkit-box-shadow: 0px 0px 5px 1px rgba(29, 29, 29, 0.75);
	-moz-box-shadow: 0px 0px 5px 1px rgba(29, 29, 29, 0.75);
	border-radius: 8px;
}
.drawer__box__light {
	background-color: white;
	padding: 15px;
	box-shadow: 0px 0px 5px 0px rgba(224, 224, 224, 0.75);
	-webkit-box-shadow: 0px 0px 5px 0px rgba(224, 224, 224, 0.75);
	-moz-box-shadow: 0px 0px 5px 0px rgba(224, 224, 224, 0.75);
	border-radius: 8px;
}
.drawer__box__blue {
	background-color: $item__background__blue;
	padding: 15px;
	box-shadow: 0px 0px 5px 1px #021426;
	-webkit-box-shadow: 0px 0px 5px 1px #021426;
	-moz-box-shadow: 0px 0px 5px 1px #021426;
	border-radius: 8px;
}
.drawer__warningPermissionsIcon {
	font-size: 30px;
	color: $item__warning;
	text-align: center;
	margin-bottom: -1px;
}
.drawer__tag {
	width: 90px;
}
.drawer__typeTag {
	width: 120px;
	text-align: center;
}
